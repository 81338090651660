import React from "react";
import Team3 from "components/Team3/team3";
import Intro5 from "components/Intro5/intro5";
import AboutUs6 from "components/About-us6/about-us6";
import Services6 from "components/Services6/services6";
import CaseStudy from "components/Case-study/case-study";
import NavbarArch from "components/Navbar-arch/navbar-arch";
import LatestNews from "components/Latest-news/latest-news";
import WorksStyle5 from "components/Works-style5/works-style5";
import FooterArch from "components/Footer-arch/footer-arch.jsx";
import ContactArch from "components/Contact-arch/contact-arch";
import TestimonialsArch from "components/Testimonials-arch/testimonials-arch";
import LightTheme from "layouts/Light";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import Intro4 from "components/Intro4/intro4";

const Documentation = () => {
  const fixedSlider = React.useRef(null);
  const MainContent = React.useRef(null);
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    const adjustMargin = () => {
      if (fixedSlider.current && MainContent.current) {
        MainContent.current.style.marginTop = fixedSlider.current.offsetHeight + "px";
      }
    };
    
    adjustMargin();
    window.addEventListener('resize', adjustMargin);

    const handleScroll = () => {
      const navbar = navbarRef.current;
      const logo = logoRef.current;

      if (navbar && logo) {
        if (window.pageYOffset > 300) {
          navbar.classList.add("nav-scroll");
          logo.setAttribute("src", appData.darkLogo);
        } else {
          navbar.classList.remove("nav-scroll");
          logo.setAttribute("src", appData.lightLogo);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("resize", adjustMargin);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <LightTheme useSkin>
      <Navbar navbarRef={navbarRef} lr={logoRef} />
      <Intro4 blackStar />

      <WorksStyle5 />

      <Footer />
    </LightTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Documentation</title>
    </>
  );
};

export default Documentation;
